import React from 'react'
import { graphql, Link } from 'gatsby'

import theme from '../../theme'
import Div from '../../elements/Div'
import Header from '../../elements/Header'
import Nav from '../../elements/Nav'
import H1 from '../../elements/H1'

import Layout from '../../components/layout'
import TextButton from '../../components/TextButton'
import Flex from '../../components/Flex'
import Logo from '../../components/Logo'

export default ({ data, pageContext: { nextPage, prevPage } }) => (
  <Layout>
    <Nav>
      <Link to="/">
        <Div p={3}>
          <Logo />
        </Div>
      </Link>
    </Nav>

    <Header px={3} py={3} textAlign="center">
      <H1 my={0} fontSize={2}>
        Gradients
      </H1>
    </Header>
    <Flex alignItems="center" justifyContent="center" mb={3}>
      <TextButton
        mr={2}
        borderRadius="5px"
        px={3}
        bg="red.0"
        color="red.7"
        fontSize={0}
      >
        Red
      </TextButton>

      <TextButton
        mr={2}
        borderRadius="5px"
        px={3}
        bg="orange.0"
        color="orange.9"
        fontSize={0}
      >
        Orange
      </TextButton>
      <TextButton
        mr={2}
        borderRadius="5px"
        px={3}
        bg="yellow.0"
        color="yellow.9"
        fontSize={0}
      >
        Yellow
      </TextButton>
      <TextButton
        mr={2}
        borderRadius="5px"
        px={3}
        bg="green.0"
        color="green.9"
        fontSize={0}
      >
        Green
      </TextButton>
      <TextButton
        mr={2}
        borderRadius="5px"
        px={3}
        bg="blue.0"
        color="blue.7"
        fontSize={0}
      >
        Blue
      </TextButton>
      <TextButton
        mr={2}
        borderRadius="5px"
        px={3}
        bg="indigo.0"
        color="indigo.7"
        fontSize={0}
      >
        Indigo
      </TextButton>
      <TextButton
        mr={2}
        borderRadius="5px"
        px={3}
        bg="violet.0"
        color="violet.7"
        fontSize={0}
      >
        Violet
      </TextButton>
      <TextButton
        mr={2}
        borderRadius="5px"
        px={3}
        bg="gray.3"
        color="gray.9"
        fontSize={0}
      >
        Gray
      </TextButton>
    </Flex>
    <Div maxWidth="72em" mx="auto">
      <Div
        display="grid"
        gridTemplateColumns="repeat(auto-fill, minmax(20%,1fr))"
        px={3}
      >
        {data.gradients.nodes.map((gradient, i) => (
          <div
            key={i}
            css={{
              height: 120,
              width: '100%',
              ...gradient.data
            }}
          />
        ))}
      </Div>
    </Div>
    <Flex maxWidth="72em" mx="auto" px={3} pt={3}>
      {prevPage && (
        <Link
          style={{
            color: theme.colors.gray[9],
            fontWeight: 700,
            textDecoration: 'none',
            marginRight: 'auto'
          }}
          to={prevPage}
        >
          Previous
        </Link>
      )}
      {nextPage && (
        <Link
          style={{
            color: theme.colors.gray[9],
            fontWeight: 700,
            textDecoration: 'none',
            marginLeft: 'auto'
          }}
          to={nextPage}
        >
          Next
        </Link>
      )}
    </Flex>
  </Layout>
)

export const pageQuery = graphql`
  query GradientList($limit: Int, $skip: Int) {
    gradients: allGradientsJson(limit: $limit, skip: $skip) {
      nodes {
        data {
          backgroundColor
          backgroundImage
        }
      }
    }
  }
`
